@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

.dark-theme {
    --colour-primary: #cd1d1d;
    --colour-primary-rgb: 205, 29, 29;
    --colour-round-background-rgb: #ffffff;
    --colour-round-background-rgb: 255,255,255;
    --colour-secondary: #0f0f43;
    --colour-secondary-rgb: 15, 15, 67;
    --colour-neutral: #ffffff;
    --colour-blank: #353030;
    --colour-blank-rgb: 53, 48, 48;
    --colour-confirm: #00ff00;
    --colour-confirm-rgb: 0, 255, 0;
    --colour-progressbar: #e78c50;
    --colour-progressbar-rgb: 231, 140, 80;
}

.App {
    --header-font: 'Amatic SC', cursive;
    --popout-color: var(--colour-primary);
    --popout-text-color: var(--colour-neutral);
    --secondary-text-color: var(--colour-secondary);
    --secondary-color: var(--colour-neutral);
    --main-color: var(--colour-secondary);
    --main-text-color: var(--colour-neutral);
    --max-page-width: 900px;
    --page-width: calc(min(100vw, var(--max-page-width)));
    --history-width: calc(2/3*var(--page-width) - 20px);
    height:100%;
    margin: auto;
    /*max-width: var(--page-width);*/
}
.App-wrapper {
    min-width: 100vw;
    min-height: 100%;
    height: auto;
    #background-color: rgba(var(--colour-blank-rgb), 0.8);
    position: absolute;
    z-index: -300;
}


#root, .bodyWrapper, body {
    overflow-x: hidden;
    width: 100vw;
}

button {
    border-radius: 5px;
    background-color: #258a16;
    color: white;
}
button:hover {
    cursor: pointer;
}

.ButtonCancel {
    background-color: #d91818;
    color: black;
}