.TshirtPattern-FormWrapper {
    background-color: #0f0f43;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.TshirtPattern-FormField > select,
.TshirtPattern-FormField > input {
    margin-right: 5px;
    margin-left: 5px;
    max-width: 50px;
}

.FormField-Question {
    padding-left: 10px;
}

.FormField-Question:hover{
    cursor: pointer;
}

.TshirtPattern-FormField {
    display: flex;
    align-items: center;
}
.FormFieldWrapper {
    padding-bottom: 5px;
    padding-top: 5px;
}