.ComingSoon-Wrapper {
    height: 100vh;
    overflow: auto;
    background-color: #DB883C;
}

.ComingSoon-ImageWrapper >img {
    max-width: 100vw;
    max-height: 80vh;
}
.ComingSoon-ContentWrapper {
    width: 100vw;
    align-content: center;
    justify-content: center;
    display: flex;
}
.ComingSoon-ContentPadding,
.ComingSoon-Content {
    flex: 1
}

.ComingSoon-TextWrapper{
    display: flex
}