.TshirtPattern-OutputWrapper {
}

.RenderedShape {
    width: 100%;
    overflow-x: auto;
}

.RenderedShape > svg {
    white-space: nowrap;
}